




import {Component, I18nViewMixin} from "@/mixins/combinations"

@Component({ components: {} })
	export default class Error extends I18nViewMixin {

}
