import Vue from "vue"
import "./plugins/routerHooks"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./plugins/router"
import i18n from "./plugins/i18n"
import vuetify from "./plugins/vuetify"

if (navigator && navigator.serviceWorker) {
	let isRefreshing = false
	navigator.serviceWorker.addEventListener("controllerchange", () => {
		if (isRefreshing) return
		isRefreshing = true
		window.location.reload()
	})
}

Vue.config.productionTip = false

new Vue({
	router,
	i18n,
	vuetify,
	render: (h) => h(App)
}).$mount("#app")
