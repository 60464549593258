import Vue from "vue"
import Router, { RouteConfig } from "vue-router"
import Routing from "@/managers/session/routing";

const addToCartView = () => import(/* webpackChunkName: "shopping" */ "@/views/AddToCart.vue")
const checkoutView = () => import(/* webpackChunkName: "shopping" */ "@/views/Checkout.vue")

const orderView = () => import(/* webpackChunkName: "order" */ "@/views/Order.vue")
const orderEditView = () => import(/* webpackChunkName: "order" */ "@/views/OrderEdit.vue")

const expiredView = () => import(/* webpackChunkName: "common" */ "@/views/Expired.vue")
const errorView = () => import(/* webpackChunkName: "common" */ "@/views/Error.vue")

Vue.use(Router)

const routes: Array<RouteConfig> = [
	{
		name: "addToCart",
		path: "/",
		props: true,
		meta: {i18n: "addToCart"},
		component: addToCartView,
	},
	{
		name: "checkout",
		path: "/checkout",
		props: true,
		meta: {i18n: "checkout"},
		component: checkoutView
	},
	{
		name: "order",
		path: "/order",
		props: true,
		meta: {i18n: "order"},
		component: orderView
	},
	{
		name: "orderEdit",
		path: "/orderEdit",
		props: true,
		meta: {i18n: "orderEdit"},
		component: orderEditView
	},
	{
		name: "expired",
		path: "/expired",
		meta: {i18n: "expired"},
		component: expiredView
	},
	{
		name: "error",
		path: "/error",
		meta: {i18n: "error"},
		component: errorView
	}
]

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: () => ({ x: 0, y: 0 })
})

router.beforeEach((to, from, next) => {
	Routing.isRouting = true
	next()
})

router.afterEach(() => {
	Routing.isRouting = false
})

export default router
