import Configuration from "@/managers/system/configuration"
import PortalClient, {ExtensionHandler} from "@chaosinsight/portalclient"
import "@chaosinsight/postoffice-portalclient"

class Portal {
	public readonly client!: PortalClient

	public get call(): ExtensionHandler {
		return this.client.call
	}

	constructor() {
		this.client = new PortalClient(Configuration.portalPath!, "6")
	}
}

export default new Portal()
