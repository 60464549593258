import I18n from "@/plugins/i18n"
import type {TranslateResult, Values} from "vue-i18n"
import Configuration from "@/managers/system/configuration"
import Notifications from "@/managers/session/notifications"

class Localization {
	public get current(): string {
		return I18n.locale
	}
	public set current(value: string) {
		I18n.locale = value
	}

	public get languages(): string[] {
		return I18n.availableLocales
	}

	public get languageNames(): {[key: string]: string} {
		return Configuration.localization.names
	}

	constructor() {
		this.setAttributes(this.current)
	}

	public translate(path: string, values?: Values): TranslateResult {
		return I18n.t(path, values)
	}

	private setAttributes(languageCode: string): void {
		try {
			window.document.documentElement.setAttribute("lang", languageCode)
			window.document.documentElement.setAttribute("xml:lang", languageCode)
			document.head.querySelector("meta[http-equiv=\"Content-Language\"]")!.setAttribute("content", languageCode)
		} catch (error) {
			Notifications.warning("Failed to update language attributes", error as Error)
		}
	}
}

export {TranslateResult}
export default new Localization()
