import "@/sass/styles.sass"
import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

export default new Vuetify({
	breakpoint: {
		mobileBreakpoint: 1023,
		thresholds: {
			xs: 600,
			sm: 768,
			md: 1024,
			lg: 1220
		}
	},
	theme: {
		themes: {
			light: {
				primary: "#dd052e",
				secondary: "#e7e1dd",
				lightgrey: "#ced0d4",
				mediumgrey: "#9fa3b0",
				whitegrey: "#f3f3f5",
				purpledark: "#454c64",
				bluegrey: "#ebecee",
				gold: "#ffbb01"
			}
		}
	}
})
