class Notifications {
	public error(message: string, error: Error): void {
		/* eslint-disable no-console */
		console.log(message, error)
	}

	public warning(message: string, error?: Error): void {
		/* eslint-disable no-console */
		console.log(message, error)
	}
}

export default new Notifications()
