









import { Component, Vue } from "vue-property-decorator"
import Header from "@/components/Header.vue"
import Loading from "@/components/ui/Loading.vue"
import Error from "@/views/Error.vue"
import Routing from "@/managers/session/routing"
import Locations, {IPublicLocation} from "@/managers/api/locations"
import Notifications from "@/managers/session/notifications";
import Configuration from "@/managers/system/configuration";

@Component({ components: { Header, Loading, Error } })
	export default class App extends Vue {
	private get isReady(): boolean {
		return !Routing.isRouting
	}

	private get location(): IPublicLocation | null {
		return Locations.location
	}

	private get version(): string {
		return Configuration.version
	}

	private get fullI18nPath(): string | null {
		const hierarchy: string[] = []

		for (const matched of this.$route.matched) {
			if (matched.meta.i18n === undefined) {
				Notifications.warning("I18n key is not defined on matched view: " + matched.path)
				continue
			}
			hierarchy.push(matched.meta.i18n)
		}
		return hierarchy.length > 0 ? `views.${hierarchy.join(".")}.` : null
	}

	public created(): void {
		this.$watch(() => [
				this.getRoutingStatus(Routing.isRouting),
				this.getTitle(this.fullI18nPath),
				this.$t("siteTitle.postFix").toString()
			].filter(value => value !== null).join(" - "),
			title => document.title = title,
			{immediate: true})
	}

	private getRoutingStatus(value: boolean): string | null {
		return value
			? this.$t(`siteTitle.routingStatus.routing`).toString()
			: null
	}

	private getTitle(value: string | null): string | null {
		if (value === null)
			return null

		if (this.$te(`${value}siteTitle`))
			return this.$t(`${value}siteTitle`).toString()

		Notifications.warning("Could not find site title: " + value)

		return null
	}
}
