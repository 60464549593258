export default {
	version: process.env.VUE_APP_VERSION!,
	portalPath: process.env.VUE_APP_CONNECTIONS_PORTAL_PATH!,
	errorLogPath: "https://errorlog.postofficesocial.com",
	localization: {
		default: "da",
		fallback: "en",
		names: {
			da: "Dansk",
			en: "English"
		}
	}
}
