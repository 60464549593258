import { Component, InjectReactive, Mixins, Prop, PropSync, ProvideReactive, Vue, Watch } from "vue-property-decorator"
import I18n from "./i18n"
import I18nView from "./i18nView"
import I18nComponent from "./i18nComponent"
import StandardComponent from "@/mixins/currencyComponent";

const I18nMixin = Mixins(I18n)
const I18nViewMixin = Mixins(I18nView)
const I18nComponentMixin = Mixins(I18nComponent)
const StandardComponentMixin = Mixins(StandardComponent)

const I18nStandardComponentMixin = Mixins(I18nComponent, StandardComponent)

export {Component, InjectReactive, Prop, PropSync, ProvideReactive, Mixins, Vue, Watch}

export {I18nMixin, I18nViewMixin, I18nComponentMixin, StandardComponentMixin, I18nStandardComponentMixin}
