










import { Component, Vue } from "vue-property-decorator"
import Localization from "@/managers/session/localization";

@Component({ components: {} })
	export default class LanguageSelector extends Vue {
	private get languages(): ISelectOption[] {
		const options = Localization.languageNames
		return Object.keys(options).map(k => ({value: k, text: options[k]}))
	}

	private get language(): string {
		return Localization.current
	}
	private set language(value: string) {
		Localization.current = value
	}
}

export interface ISelectOption {
	readonly value: string
	readonly text: string
}
