import { Component, Vue } from "vue-property-decorator"
import type {TranslateResult} from "vue-i18n"
import Locations, { IPublicLocation } from "@/managers/api/locations";
import Notifications from "@/managers/session/notifications";

@Component
export default class StandardComponent extends Vue {
	protected get location(): IPublicLocation | null {
		return Locations.location
	}

	protected $c(value: number, useSymbol = false): TranslateResult {
		if (this.location !== null)
			return this.$n(value, {key: "currency", currency: this.location.Currency, currencyDisplay: useSymbol ? "narrowSymbol" : "code"})

		Notifications.warning("Location not available for currency")
		return this.$n(value, {key: "currencyNoSymbol"})
	}
}
