









import { Component, Prop, Vue } from "vue-property-decorator"

@Component({ components: {} })
	export default class Loading extends Vue {
	@Prop({ default: 24 })
	size!: number
}
