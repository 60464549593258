












import { Component, Prop, Vue } from "vue-property-decorator"
import LanguageSelector from "@/components/LanguageSelector.vue";

@Component({ components: {LanguageSelector} })
	export default class Header extends Vue {
	@Prop({required: true})
	public readonly name!: string
	@Prop({required: true})
	public readonly logo!: string
}
