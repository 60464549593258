import "@chaosinsight/postoffice-portalclient"
import type {IPublicLocation} from "@chaosinsight/postoffice-portalclient"
import Portal from "@/managers/api/portal"

class Locations {
	private lastId: string | null = null
	private lastLocation: IPublicLocation | null = null

	public get location(): IPublicLocation | null {
		return this.lastLocation
	}

	public async getLocationByToken(token: string): Promise<IPublicLocation> {
		if (this.lastId === token)
			return this.lastLocation!
		else {
			this.lastId = null
			this.lastLocation = null
		}

		const location = await Portal.call.publicLocations.get(token).response

		this.lastLocation = location
		this.lastId = token

		return location
	}

	public async getLocationByExternalId(externalId: string): Promise<IPublicLocation> {
		if (this.lastId === externalId)
			return this.lastLocation!
		else {
			this.lastId = null
			this.lastLocation = null
		}

		const location = await Portal.call.publicLocations.getByExternalId(externalId).response

		this.lastLocation = location
		this.lastId = externalId

		return location
	}
}
export { IPublicLocation }
export default new Locations()
